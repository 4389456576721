import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'
import getInitials from 'v2/views/utils/getInitials'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import { orderAlphabeticallyStacks } from 'data/utils/utils'
import { withStacks } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import TrialProgress from 'features/billing/TrialProgress'
import NotificationMenu from 'features/collaboration/NotificationMenu'
import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import CustomBackendSetup from 'utils/CustomBackendSetup'

import { Flex, Icon, Link, ScrollBox, Tooltip } from 'v2/ui'
import { Warning } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import Button from 'ui/deprecated/atoms/Button'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import analytics from '../../utils/analytics'
import CommandBarLauncher from '../commandbar/CommandBarLauncher'

import { AppBarButton } from './AppBarButton'
import ErrorMenu from './ErrorMenu'
import NewAppButton from './NewAppButton'
import { StackIcon } from './StackIcon'
import UserMenu from './UserMenu'

const { colors } = stackerTheme()

const ButtonSize = 42
const ButtonLineHeight = ButtonSize + 6 + 'px' // unit is required
export const AppBarWidth = '60px' // unit is required

const _DemoLabel = styled('span')`
    background: #d00000;
    position: absolute;
    right: -5px;
    bottom: 2px;
    padding: 1px 2px 0px 3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    color: white;
    line-height: 13px;
    font-family: 'Inter UI';
    letter-spacing: 0.6px;
    border-radius: 3px;
`

const Avatar = styled.img`
    max-height: 2rem;
    max-width: 2rem;
    height: 2rem;
    width: 2rem;
    object-fit: cover;
    border-radius: 50%;
    margin: auto;
`

const WarningIconWrapper = styled.span`
    svg {
        margin: auto;
    }
`

const StackButtonTooltip = ({ label, children, offset = -12 }) => (
    <Tooltip
        popperOptions={{ modifiers: { preventOverflow: { enabled: false } } }}
        placement="right-end"
        arrow={false}
        animation="fade"
        offset={offset}
        label={label}
        wrapperStyle={{ position: 'relative' }}
        showDelay={50}
    >
        {children}
    </Tooltip>
)
export const StackButton = ({ stack, isSelected, isDisabled, navTheme }) => {
    const onLoaded = (elm) => {
        if (elm && isSelected) {
            elm.scrollIntoView()
        }
    }

    return (
        <StackButtonTooltip label={stack.name}>
            <StackLink
                style={{
                    display: 'inline-block',
                    width: ButtonSize,
                    height: ButtonSize,
                    lineHeight: ButtonLineHeight,
                    textAlign: 'center',
                    borderRadius: 8,
                    color: navTheme.highlightColor,
                }}
                isDisabled={isDisabled}
                isSelected={isSelected}
                color={stack.options.theme.brandColor}
                inactiveColor={navTheme.theme === 'light' ? '#EFF1F2' : '#4C555D'}
                href={`/${stack.url_slug}/home`}
                ref={onLoaded}
            >
                {get(stack.options, 'theme.icon') ? (
                    <StackIcon stack={stack} isSelected={isSelected} theme={navTheme} />
                ) : (
                    getInitials(stack.name)
                )}

                {stack.options?.is_demo && <_DemoLabel>Demo</_DemoLabel>}
            </StackLink>
        </StackButtonTooltip>
    )
}

const StackLink = styled(Link)`
    transition: 0.2s all ease-in-out;
    background: ${(p) => (p.isSelected ? p.color : p.inactiveColor)};
    margin-bottom: 8px;

    :hover {
        opacity: 0.9;
        background: ${(p) => p.color};
    }
`

const AppBar = ({ studioUser: user, stacks, selectedStack, navTheme, userMenuOnly }) => {
    const { hasRight } = useAccountUserContext()
    const location = useLocation()
    // always show stacks on the account pages where there is no selected app
    const showStacks = location.pathname === '/__account'
    const { workspaceAccount } = useContext(AppContext)
    const accountId = selectedStack?.account_id ? selectedStack?.account_id : workspaceAccount?._sid

    const orderedStacks = useMemo(() => {
        if ((!user || !selectedStack) && !showStacks) return null
        return stacks ? orderAlphabeticallyStacks(stacks, accountId) : []
    }, [stacks, selectedStack, user, showStacks, accountId])

    const { handleClickOutside: handleClickOutsideSidePane } = useSlidingPane()

    if (!user || !selectedStack) return null

    return (
        <Flex
            column
            bg={navTheme.bgAppBar}
            py={[1, 1, 1, 2]}
            wrap="nowrap"
            height="100%"
            maxHeight="100%"
            width={AppBarWidth}
            align="stretch"
            flexShrink={0}
            id="AppBar"
            data-testid="app-bar"
        >
            <div style={{ height: 58, alignSelf: 'center' }}>
                <NotificationMenu right={false} ml={4} pt="12px">
                    <AppBarButton
                        aria-label="Notifications"
                        ml={orderedStacks.length === 0 ? '-9px' : -4}
                        mt="-12px"
                        navTheme={navTheme}
                        isDisabled={userMenuOnly}
                        onClick={() => {
                            analytics.track('notification menu clicked', {
                                from: 'app bar',
                            })
                        }}
                    >
                        <Icon icon="bell" size="md" />
                    </AppBarButton>
                </NotificationMenu>
            </div>
            <ScrollBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                overflowY="auto"
                scrollbarColor="rgba(255, 255, 255, 0.2)"
                hideScrollbars
            >
                {orderedStacks.map((x) => (
                    <StackButton
                        navTheme={navTheme}
                        key={x._sid}
                        isDisabled={userMenuOnly}
                        user={user}
                        stack={x}
                        isSelected={selectedStack._sid === x._sid}
                        selectedStack={selectedStack}
                    />
                ))}
            </ScrollBox>
            <Flex
                column
                align="center"
                onClick={() => {
                    analytics.track('create app clicked', {
                        from: 'app bar',
                    })
                }}
            >
                {hasRight(Rights.CreateApps) && (
                    <StackButtonTooltip label="Add a new app..." offset={-4}>
                        <NewAppButton
                            data-testid="app-bar.new-app"
                            as={Button}
                            aria-label="Add a new app..."
                            type="link"
                            style={{
                                height: ButtonSize,
                                width: ButtonSize,
                                textAlign: 'center',
                                marginTop: 5,
                                padding: 0,
                                color: V4DesignSystem.colors.gray[500],
                            }}
                        >
                            <Icon icon="add" style={{ fontSize: 20 }} />
                        </NewAppButton>
                    </StackButtonTooltip>
                )}
            </Flex>
            <Flex column align="center" flexGrow={1} onClick={handleClickOutsideSidePane} />

            <Flex column>
                {hasRight(Rights.ViewSettings) && (
                    <ErrorMenu
                        as={AppBarButton}
                        navTheme={navTheme}
                        onClick={() => {
                            // @ts-ignore
                            analytics.track('error menu clicked', {
                                from: 'app bar',
                            })
                        }}
                        maxBoxHeight="300px"
                    >
                        <WarningIconWrapper>
                            <Warning color={colors.userInterface.warning[1000]} size={16} />
                        </WarningIconWrapper>
                    </ErrorMenu>
                )}

                <CommandBarLauncher navTheme={navTheme} />

                {hasRight(Rights.ViewSettings) && (
                    <AppBarButton
                        data-testid="app-bar.open-workspace-settings"
                        navTheme={navTheme}
                        aria-label="Open workspace settings"
                        onClick={() => {
                            openWorkspaceSettingsModal({ page: 'general' })
                            analytics.track('workspace settings clicked', {
                                from: 'app bar',
                            })
                        }}
                    >
                        <TrialProgress size="42px" circular alwaysReturnChildren>
                            <Icon icon="building" />
                        </TrialProgress>
                    </AppBarButton>
                )}

                <UserMenu
                    as={AppBarButton}
                    navTheme={navTheme}
                    onClick={() => {
                        analytics.track('user menu clicked', {
                            from: 'app bar',
                        })
                    }}
                >
                    {user?.options?.photo ? (
                        <Avatar src={user?.options?.photo} alt="users-avatar" />
                    ) : (
                        <Icon icon="avatar" />
                    )}
                </UserMenu>
                {/* only if enabled via env variable */}
                <CustomBackendSetup />
            </Flex>
        </Flex>
    )
}

export default withUser(withStacks(AppBar))
