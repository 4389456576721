// @ts-strict-ignore
import React, { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import { isEqual, uniqBy } from 'lodash'

import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import { Box, Button, Editable, Flex, Image, OverflowList } from 'v2/ui'
import Gallery from 'v2/ui/components/Gallery'
import AnimateRelocateContextProvider from 'v2/ui/utils/AnimateRelocate'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import Attachment from './Attachment'
import DisplayText from './DisplayText'

type FormButtonComponent = FC<any & { onClick: () => void }>

export type Attachment = {
    url: string
    filename: string
    isNotAnImage?: boolean
    type?: string
}

type Props = {
    mode: string
    onChange: (attachments: Attachment | Attachment[]) => void
    isSingle?: boolean
    layout?: string
    FormButton?: FormButtonComponent
    renderOptions?: {
        displayAsImage?: boolean
        imageAltText?: string
        useFirstImageSelector?: boolean
    }
    children: Attachment[]
}

const AttachmentsAttribute: FC<Props> = ({
    mode,
    isSingle,
    layout,
    onChange,
    FormButton,
    renderOptions = {},
    children,
}) => {
    const [, setSelectedImage] = useState<string | null>(null)
    const hasAttachments = children && children.length > 0
    const [attachments, setAttachments] = useState(
        Array.isArray(children) ? children : children ? [children] : []
    )
    // Make a copy of the attachments that we _don't_ reorder when choosing which one goes first
    const [attachmentsLocalCopy, setAttachmentsLocalCopy] = useState<Attachment[]>(attachments)

    useEffect(() => {
        const newAttachments = Array.isArray(children) ? children : children ? [children] : []
        if (!isEqual(newAttachments, attachments)) {
            setAttachments(newAttachments)
        }
    }, [children, attachments])

    const inline = layout === layouts.inline

    useEffect(() => {
        setAttachmentsLocalCopy((prevLocalCopy) => {
            // get a list of the URLs included in the incoming prop data
            const newUrls = attachments.map(({ url }) => url)

            // We want to preserve the order after an attachment has been added or removed.
            // When there is a change in the attachments, we want to remove the local ones that have been removed
            // and add the new ones at the end.
            // uniqBy is here because when an attachment has not been removed/added, it is present in both the
            // local ones and the new ones
            return uniqBy(
                [
                    ...(prevLocalCopy || []).filter(({ url }) => newUrls.includes(url)),
                    ...(attachments || []),
                ],
                ({ url }) => url
            )
        })
    }, [attachments])

    const getUrl = (attachment: Attachment): string => {
        if (typeof attachment === 'object') {
            // Airtable attachments can be a json with thumbnails
            return attachment.url
        } else {
            // Array of strings
            return attachment
        }
    }

    const urlMatches = useCallback(
        (url: string, matches: boolean = true) =>
            (attachment: Attachment) =>
                (getUrl(attachment) === url) === matches,
        []
    )

    const handleDelete = useCallback(
        (url) => {
            // Delete from both the local copy of the attachments and the 'actual' version
            setAttachmentsLocalCopy((attachmentsLocalCopy) =>
                attachmentsLocalCopy.filter(urlMatches(url, false))
            )
            const urls = attachments.filter(urlMatches(url, false))
            onChange(urls)
        },
        [attachments, onChange, urlMatches]
    )

    const handleUse = useCallback(
        (url) => {
            const selected = attachmentsLocalCopy.find(urlMatches(url, true))

            // Airtable doesn't appear to respect reordering of attachments via the API, so when a
            // different image is selected to be the first in the list, strip everything but the url
            // and filename from the other attachments so they are treated as new uploads, which
            // pushes them to the end of the list.
            const others = attachmentsLocalCopy
                .filter(urlMatches(url, false))
                .map((a) => ({ url: a.url, filename: a.filename }))

            const result: Attachment[] = []
            if (selected) {
                result.push(selected)
            }
            result.push(...others)

            // Update the actual field but not the local copy, so the selected image doesn't move
            onChange(result)
        },
        [attachmentsLocalCopy, onChange, urlMatches]
    )

    const handleUpload = useCallback(
        (value) => {
            if (isSingle) {
                // If this is a single image field, we just save the URL directly to the field
                onChange(value.filesUploaded.map((f) => ({ url: f.url, filename: f.filename }))[0])
            } else {
                // Otherwise, we save an array of urls
                let urls = value.filesUploaded.map((f) => ({
                    url: f.url,
                    filename: f.filename,
                }))
                setAttachmentsLocalCopy((attachmentsLocalCopy) => attachmentsLocalCopy.concat(urls))
                // append to current files
                if (Array.isArray(children)) {
                    urls = children.concat(urls)
                }
                onChange(urls)
            }
        },
        [isSingle, onChange, children]
    )

    const form = (
        <ReactFilestack
            options={{
                maxFiles: isSingle ? 1 : 50,
            }}
            onSuccess={handleUpload}
            customRender={({ onPick }) => {
                if (FormButton) {
                    return <FormButton onClick={onPick} />
                }

                return (
                    <Button
                        variant="moderateSm"
                        padding="small"
                        icon="add"
                        onClick={onPick}
                        width="thumbnail.lg"
                        minHeight={hasAttachments ? 'thumbnail.lg' : null}
                    >
                        Add
                    </Button>
                )
            }}
        />
    )

    let display

    const getAttachments = (canDelete?: boolean, props: object = {}): ReactNode[] | null => {
        if (!attachmentsLocalCopy) {
            return null
        }

        return attachmentsLocalCopy.map((attachment, index) => (
            <React.Fragment key={getUrl(attachment)}>
                {/* @ts-ignore */}
                <Attachment
                    index={index}
                    onImageClick={setSelectedImage}
                    attachment={attachment}
                    canDelete={canDelete}
                    onDelete={handleDelete}
                    canSelect={urlMatches(getUrl(attachment), false)(attachments?.[0])}
                    onSelect={handleUse}
                    size={inline ? 'sm' : 'lg'}
                    {...props}
                    mb={0}
                    boxShadow={
                        renderOptions.useFirstImageSelector &&
                        urlMatches(getUrl(attachment), true)(attachments?.[0])
                            ? 'lightblue 0 0 0px 3px'
                            : null
                    }
                    useFirstImageSelector={renderOptions.useFirstImageSelector}
                />
            </React.Fragment>
        ))
    }

    if (hasAttachments) {
        if (renderOptions?.displayAsImage) {
            if (!hasAttachments || !attachments.length) return null
            let imageAttachment = attachments[0]?.url
            display = (
                <Image
                    alignSelf="flex-start"
                    src={imageAttachment}
                    alt={renderOptions?.imageAltText}
                />
            )
        } else {
            if (!Array.isArray(attachments)) {
                Sentry.captureMessage(
                    `Expected a list of urls when using multi_file, but got: ${attachments}`
                )

                return <span>-</span>
            }

            // Only wrap in the OverflowList if we are displaying "inline" and want to
            // truncate the list to one line
            if (inline) {
                display = (
                    <Gallery selector=".attachment-gallery-item">
                        {
                            // @ts-ignore
                            <OverflowList key="overflowList" maxVisible={10} ignoreWrapper>
                                {getAttachments(mode === modes.editing, { display: 'inline' })}
                            </OverflowList>
                        }
                    </Gallery>
                )
            } else {
                display = (
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        maxWidth="100%"
                        maxHeight="100%"
                        alignItems="center"
                    >
                        <Gallery selector=".attachment-gallery-item">{getAttachments()}</Gallery>
                    </Box>
                )
            }
        }
    } else {
        // @ts-ignore
        display = <DisplayText>-</DisplayText>
    }

    if (mode === modes.editing) {
        const editor = (
            <Flex width="100%">
                <AnimateRelocateContextProvider>
                    <Gallery selector=".attachment-gallery-item">{getAttachments(true)}</Gallery>
                </AnimateRelocateContextProvider>
                {(!isSingle || !attachments || attachments.length === 0) && (
                    <Box alignSelf="stretch" pb={2}>
                        {form}
                    </Box>
                )}
            </Flex>
        )

        if (layout === layouts.inline) {
            return <Editable input={() => editor} display={() => display} onChange={onChange} />
        } else {
            //This will show up on the admin and detail/create pages
            return editor
        }
    }
    // Just display the attachements with thumbnails, without remove icon or Upload button (form)
    return <>{display}</>
}

export default memo(AttachmentsAttribute)
