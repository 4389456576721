import React, { useContext, useState } from 'react'

import { Global } from '@emotion/react'

import AppContext from 'app/AppContext'
import settings from 'app/settings'
import { resumeSubscription } from 'data/api/subscriptionApi'
import { invalidateAccounts, useUpdateAccount } from 'data/hooks/accounts'
import { formatSubscriptionCancelDate, shouldDisplayCancelDate } from 'features/billing/utils'
import { Divider, Frame } from 'features/workspace/WorkspaceSettingsModalUi'
import useTrack from 'utils/useTrack'

import { Button, Toast } from 'v2/ui'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import useLDFlags from '../../data/hooks/useLDFlags'

import BillingInfo from './BillingInfo'
import { isSubscriptionCancelled } from './stackerUserUtils'

export default function WorkspaceBillingSettings({ children }) {
    const { track } = useTrack()
    const { workspaceAccount } = useContext(AppContext)
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const [billingError, setBillingError] = useState()
    const customerId = workspaceAccount?.stripe_customer_id
    const title = customerId ? 'Billing' : 'Choose a plan'
    const { flags } = useLDFlags()
    const customerSubscribed =
        workspaceAccount?.stripe_customer_id &&
        workspaceAccount?.stripe_subscription_id &&
        !isSubscriptionCancelled(workspaceAccount)
    const showCancelButton = flags.churnkeyIntegration && customerSubscribed

    const subscriptionEndDateISO = workspaceAccount?.subscription_end_date
    const displayCancelDate = shouldDisplayCancelDate(workspaceAccount)
    const formattedCancelDate = formatSubscriptionCancelDate(subscriptionEndDateISO)

    const churnkeyInit = () => {
        track('WIP - Frontend - Billing Settings - Cancellation Started')
        window.churnkey.init('show', {
            authHash: workspaceAccount?.churnkey_user_hash,
            customerId: workspaceAccount?.stripe_customer_id,
            subscriptionId: workspaceAccount?.stripe_subscription_id,
            appId: settings.CHURNKEY_APP_ID,
            mode: settings.IS_PROD ? 'live' : 'test',
            provider: 'stripe',
            onCancel: (customer) => {
                const cancel_at = customer?.subscriptions?.data?.[0]?.cancel_at
                let subscription_end_date
                // If we have a cancel at date string, then convert to iso string and update
                // against the account
                if (cancel_at && !isNaN(cancel_at)) {
                    subscription_end_date = new Date(cancel_at * 1000).toISOString()
                }
                setBillingError(null)
                // Update the local copy of account stored in state
                // The version on the server is updated with Stripe webhoooks
                updateAccount({
                    id: workspaceAccount._sid,
                    patch: { subscription_status: 'cancelled', subscription_end_date },
                })
                    .then(() => {
                        track('WIP - Frontend - Billing Settings - Cancellation Completed')
                        invalidateAccounts()
                    })
                    .catch(() => {
                        track('WIP - Frontend - Billing Settings - Cancellation Failed')
                        setBillingError('There was an error. Please try again.')
                    })
            },
        })
    }
    return (
        <Frame title={title} flex={1}>
            <BillingInfo />
            {showCancelButton ? (
                <>
                    <Global
                        styles={{
                            '.ck-style': {
                                zIndex: 1500,
                                position: 'relative',
                            },
                        }}
                    />
                    <Divider />
                    <Flex style={{ alignItems: 'center', ...V4DesignSystem.h2 }}>
                        <div style={{ flex: 1 }}>Cancel Subscription</div>
                        <Button variant="moderateSm" onClick={churnkeyInit} icon="alert">
                            Cancel Subscription
                        </Button>
                    </Flex>
                </>
            ) : (
                <>
                    <Divider />
                    {displayCancelDate && <p>{formattedCancelDate}</p>}
                </>
            )}
            {isSubscriptionCancelled(workspaceAccount) && (
                <Button
                    buttonSize="medium"
                    disabled={false}
                    aria-label="Resume subscription"
                    mb={2}
                    width="min-content"
                    variant="Primary"
                    onClick={() => resumeSubscription()}
                >
                    Resume Subscription
                </Button>
            )}
            {children}
            <Toast
                status="error"
                show={!!billingError}
                onCloseComplete={() => {
                    setBillingError(null)
                }}
                title={billingError}
            />
        </Frame>
    )
}
