import { getCustomBackendConfig } from 'utils/getCustomBackendConfig'

let BACKEND_ENDPOINT =
    window.runtimeSettings?.REACT_APP_BACKEND_ENDPOINT ||
    process.env.REACT_APP_BACKEND_ENDPOINT ||
    'http://local.stackerlabs.com:8000'
const DOMAIN =
    window.runtimeSettings?.REACT_APP_DOMAIN || process.env.REACT_APP_DOMAIN || 'stacker.local:8443'
const VERSION = process.env.REACT_APP_VERSION || 'unknown'
let USER_DOMAIN
let WORKSPACE_DOMAIN
let STUDIO_URL
let SUPPORTED_STUDIO_DOMAINS

export const SERVER_PAGE_SIZE = 1000
switch (DOMAIN) {
    case 'airportal.stacker.app':
        USER_DOMAIN = 'my.stacker.app'
        WORKSPACE_DOMAIN = 'stackerhq.com'
        // Support accessing studio through either stackerhq.com or airportal.app top level domains
        // This should be removed once we have redirects from studio.airportal.app to studio.stackerhq.com
        SUPPORTED_STUDIO_DOMAINS = ['studio.stackerhq.com', 'studio.airportal.app']
        STUDIO_URL = SUPPORTED_STUDIO_DOMAINS[0]
        break
    case 'stacker.dev':
        USER_DOMAIN = 'my.stacker.dev'
        WORKSPACE_DOMAIN = 'stacker.dev'
        STUDIO_URL = 'studio.stacker.dev'
        SUPPORTED_STUDIO_DOMAINS = [STUDIO_URL]
        break
    default:
        USER_DOMAIN = DOMAIN
        WORKSPACE_DOMAIN = DOMAIN
        STUDIO_URL = `studio.${DOMAIN}`
        SUPPORTED_STUDIO_DOMAINS = [STUDIO_URL]
        break
}

export const THUMBNAILS_URL = 'https://thumbnails.stackerhq.com/'

const IS_LOCAL_INSTANCE = DOMAIN.endsWith('.local') || DOMAIN.endsWith('.local:8443')
const IS_PROD = DOMAIN === 'airportal.stacker.app'

const pusherSettings = {
    dev: {
        key: 'f399d0b5d0c230aa15a9',
        cluster: 'eu',
    },
    production: {
        key: 'dbeb9e93fe4d62e3ac07',
        cluster: 'eu',
    },
}
let HOST = process.env.REACT_APP_HOST || window.location.host.replace(/^www\./, '')

/**
 * @see https://www.notion.so/stacker/Use-any-workspace-locally-ad6f88a16bf84917b3c2b3e3efd41b58
 * USE_CUSTOM_BACKEND is the proxy's url
 */
let USE_CUSTOM_BACKEND = process.env.REACT_APP_USE_CUSTOM_BACKEND

if (USE_CUSTOM_BACKEND) {
    const isActive = window.localStorage.getItem('customBackendSettings_isActive')
    if (isActive === '1') {
        const origin = getCustomBackendConfig()['x-origin'] // used to compute which workspace you're on

        if (origin) {
            HOST = origin.replace(/https?:\/\//, '')
            BACKEND_ENDPOINT = USE_CUSTOM_BACKEND
        }
    }
}

const billflow = {
    dev: {
        plan_picker_id: 'NWlIMQYG0y6WpJbY9Zx0',
        customer_portal_id: 'bNPUMNIjFADHClTE22Py',
    },
    prod: {
        plan_picker_id: 'pASPbWpZEdLId5AqS2GQ',
        customer_portal_id: 'z3jpny98fUdLfdMqelcB',
    },
}

const userflow = {
    dev: {
        key: 'ct_4mp5vdohrzcnbetol4o5uqbpx4',
        checklist_key: 'c92f21dd-eb6b-4aca-809c-953bd8b99250',
        add_field_on_list_view_key: 'ba8ccf5a-306c-49a5-a3db-6e635ca39622',
    },
    prod: {
        key: 'ct_zlkv6zfkzrhehczm65idxifnru',
        checklist_key: 'c92f21dd-eb6b-4aca-809c-953bd8b99250',
        add_field_on_list_view_key: 'ba8ccf5a-306c-49a5-a3db-6e635ca39622',
    },
}

const segment = {
    dev: {
        key: 'jBX8DaLdGebfwXz32MqDA5hp355HjmfX',
    },
    prod: {
        key: 'ecCCSNBBa7Xs1RPxb1pAmPIw2JweIMoG',
    },
}

const launchDarkly = {
    dev: {
        clientSideId: '61ae509b319ed311a91abed1',
        logLevel: 'info',
    },
    prod: {
        clientSideId: '61ae509b319ed311a91abed2',
        logLevel: 'error',
    },
}

const filestack = {
    dev: {
        key: 'AWNlF7GwvTci1r6RDTFmkz',
        location: 'gcs',
        cname: null,
    },
    prod: {
        key: 'Arfn2PRozQ26eRkdQy74Ez',
        location: 'gcs',
        cname: 'files.stackerhq.com',
    },
}

const settings = {
    DOMAIN,
    VERSION,
    NODE_ENV: process.env.NODE_ENV,
    API_ROOT: `${BACKEND_ENDPOINT}/api/`,
    BACKEND_ENDPOINT: BACKEND_ENDPOINT,
    SALESFORCE_DATA_CONNECTION_CLIENT_ID:
        '3MVG9ZF4bs_.MKujCaK6XtNXyd7JRIIQK9lNxbGUrxLzwQ4gZBKGOEmkRlW7Cwt623_f8xAweYo4yBiSbZm.m',
    DEBUG: process.env.NODE_ENV === 'development',
    IS_PROD,
    USER_DOMAIN,
    WORKSPACE_DOMAIN,
    STUDIO_URL: (IS_LOCAL_INSTANCE ? 'http://' : 'https://') + STUDIO_URL,
    SUPPORTED_STUDIO_DOMAINS,
    STUDIO_DOMAIN: STUDIO_URL,
    IS_LOCAL_INSTANCE,
    OAUTH_REDIRECT_URL: IS_LOCAL_INSTANCE
        ? 'http://local.stackerlabs.com:8000/api/' // DNS redirect to 127.0.0.1
        : `${BACKEND_ENDPOINT}/api/`,
    AUTH0_LOGOUT_URL: `${BACKEND_ENDPOINT}/api/auth0/logout/`,
    FULL_STORY_ORG_ID: 'VMPSV',
    COHERE_KEY: 'ZylXNj0zKafs1zv0yo6i-FGf',
    PUSHER: IS_PROD ? pusherSettings.production : pusherSettings.dev,
    USE_CUSTOM_BACKEND,
    HOST,
    BILLFLOW: IS_PROD ? billflow.prod : billflow.dev,
    USERFLOW_KEY: IS_PROD ? userflow.prod.key : userflow.dev.key,
    USERFLOW_CHECKLIST_KEY: IS_PROD ? userflow.prod.checklist_key : userflow.dev.checklist_key,
    USERFLOW_ADD_FIELD_ON_LIST_KEY: IS_PROD
        ? userflow.prod.add_field_on_list_view_key
        : userflow.dev.add_field_on_list_view_key,
    SEGMENT_KEY: IS_PROD ? segment.prod.key : segment.dev.key,
    LAUNCH_DARKLY: IS_PROD ? launchDarkly.prod : launchDarkly.dev,
    FILESTACK: IS_PROD ? filestack.prod : filestack.dev,
    COMMANDBAR_ORG_ID: 'c76d9553',
    CHURNKEY_APP_ID: '2glmy9522',
    ASSETS_URL: window.runtimeSettings?.REACT_ASSETS_URL,
}

export default settings

const PROD_GOOGLE_DRIVE_PICKER_API_KEY = 'AIzaSyDI9WhwXAycSqW2FqPIHEUXqnOeX5Bvmys'
const PROD_GOOGLE_OAUTH_CLIENT_ID =
    '535214121568-6emlq7v9010pufjrnk4dprqbk2l2cmh6.apps.googleusercontent.com'
const PROD_GOOGLE_OAUTH_APP_ID = '535214121568'

const DEV_GOOGLE_DRIVE_PICKER_API_KEY = 'AIzaSyD-4G6IZWkauhPGV5AVUqJQFtTHov_iq20'
const DEV_GOOGLE_OAUTH_CLIENT_ID =
    '183722971109-b7qg97jg9gbnubls60ir0l4j3kgdn1q6.apps.googleusercontent.com'
const DEV_GOOGLE_OAUTH_APP_ID = '183722971109'

const PROD_STREAM_APP_ID = 79676
const PROD_STREAM_API_KEY = 'z6cgfsfc48ec'

const DEV_STREAM_APP_ID = 79674
const DEV_STREAM_API_KEY = 'pdserjphns75'

export const GOOGLE_DRIVE_PICKER_API_KEY = IS_PROD
    ? PROD_GOOGLE_DRIVE_PICKER_API_KEY
    : DEV_GOOGLE_DRIVE_PICKER_API_KEY
export const GOOGLE_OAUTH_CLIENT_ID = IS_PROD
    ? PROD_GOOGLE_OAUTH_CLIENT_ID
    : DEV_GOOGLE_OAUTH_CLIENT_ID

export const Auth0Settings = {
    domain: IS_LOCAL_INSTANCE
        ? 'stacker-dev.eu.auth0.com'
        : IS_PROD
        ? 'stackerhq.eu.auth0.com'
        : 'stacker-staging.eu.auth0.com', //TODO: Remov
    clientId: IS_LOCAL_INSTANCE
        ? 'mZjO9bRKqn3zy3dqrgzaacLlmmk1jLSk'
        : IS_PROD
        ? 'EfNuYg7yAfbIoDAbrXWOuU165fAldxW1'
        : '9BXbcnJGyxvh1CW0c9zBqNV34C82uTsk',
    audience: `${BACKEND_ENDPOINT}/api`,
}
export const GOOGLE_OAUTH_APP_ID = IS_PROD ? PROD_GOOGLE_OAUTH_APP_ID : DEV_GOOGLE_OAUTH_APP_ID

export const STREAM_APP_ID = IS_PROD ? PROD_STREAM_APP_ID : DEV_STREAM_APP_ID

export const STREAM_API_KEY = IS_PROD ? PROD_STREAM_API_KEY : DEV_STREAM_API_KEY

export const DD_RUM_APP_ID = '6c5b4ffc-b05f-47a7-9405-579eb9c2ce53'
export const DD_RUM_TOKEN = 'pub4f8219c928a5fb3a753710f5339e5af7'

export const AUTH0_ACCESS_TOKEN_LOCALSTORAGE_KEY = 'auth0_access_token'

export const LIGHTGALLERY_LICENSE_KEY = '85ED9437-274F47D0-B8C276A2-21E2F125'

export const SYNCFUSION_KEY =
    'ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNhWX5ccHdWQWdUVkw='
