import React, { FC } from 'react'

import { AppBarButton } from 'features/AppBar/AppBarButton'

import { Icon } from '../../v2/ui'

type LauncherProps = {
    navTheme: Record<string, string>
}

const handleClick = () => {
    window.CommandBar.open()
}

const Launcher: FC<LauncherProps> = ({ navTheme }) =>
    window.CommandBar ? (
        <AppBarButton
            wrap="nowrap"
            navTheme={navTheme}
            cursor="pointer"
            aria-label="Open CommandBar"
            onClick={handleClick}
        >
            <Icon icon="search" />
        </AppBarButton>
    ) : null

export default Launcher
